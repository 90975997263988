<template>
  <ValidationDons />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ValidationDons from '../../components/workflow/ValidationDons';

export default {
  name: 'ValidationDonsContainer',
  components: {
    ValidationDons,
  },
  data: () => ({}),
  computed: {
    ...mapGetters('session', {
      isUserNational: 'isUserNational',
      getUserCdpeId: 'getUserCdpeId',
    }),
  },
  created() {
    if (!this.isUserNational) {
      this.loadValidationDons({
        cdpeId: this.getUserCdpeId,
      });
    } else {
      this.loadFilterDepartements();
    }
  },
  methods: {
    ...mapActions('workflows/dons', ['loadValidationDons']),
    ...mapActions('cdpe', ['loadFilterDepartements']),
  },
};
</script>
