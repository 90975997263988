var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-card',[_c('div',{staticClass:"pa-5 mb-5"},[_c('v-card-title',{staticClass:"title",attrs:{"primary-title":""}},[_vm._v(" Validation des dons ")]),(_vm.isUserNational)?_c('div',[(!_vm.currentCdpeId)?_c('v-alert',{attrs:{"color":"primary","dense":"","type":"info"}},[_vm._v(" Veuillez sélectionner un "),_c('strong',[_vm._v("Département")])]):_vm._e(),_c('div',{staticClass:"text-right"},[_c('v-autocomplete',{attrs:{"items":_vm.filterDepartements,"label":"Département","item-text":function (item) { return ((item.code) + " - " + (item.nom)); },"item-value":"id"},on:{"change":_vm.changeDepartement}})],1)],1):_vm._e()],1)])],1),_c('div',[_c('div',[_c('v-card',[_c('div',{staticClass:"pa-5 mb-5"},[_c('v-card-title',{staticClass:"title",attrs:{"primary-title":""}},[_vm._v(" Confirmation d'envoi ")]),_c('div',{staticClass:"mb-5"},[_c('v-row',[_c('v-col',[_vm._v("Montant total :")]),_c('v-col',[_vm._v(_vm._s(_vm._f("currency")(_vm.amountToSend)))])],1)],1),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.canNotBeSend},on:{"click":_vm.confirmValidateDons}},[_vm._v(" Valider les dons ")])],1)])],1)]),_c('div',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"light":"","headers":_vm.headers,"items":_vm.validatedDons,"item-key":"key","show-select":"","loading":_vm.loading,"loading-text":"Chargement en cours","no-data-text":"Aucun dons à valider","items-per-page":50,"footer-props":{
        itemsPerPageText: 'Dons par page:',
        itemsPerPageOptions: [20, 50, 100, 200],
      }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Aucun dons à valider ")]},proxy:true},{key:"header.data-table-select",fn:function(){return [_c('v-checkbox',{attrs:{"input-value":_vm.selectAllValue,"indeterminate":_vm.selectAllIndeterminate},on:{"click":function($event){$event.stopPropagation();return _vm.toggleAll($event)}}})]},proxy:true},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date))+" ")]}},{key:"item.montant",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.montant))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"blue"},scopedSlots:_vm._u([{key:"progress",fn:function(){return undefined},proxy:true}],null,false,63074868)}):_vm._e()],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e()],1),_c('Downloader',{attrs:{"downloading-file":_vm.downloadingFile,"label":"bordereau de validation"},on:{"downloadSuccess":_vm.onDownloadSuccess}}),(_vm.dialog)?_c('ConfirmedModal',{attrs:{"sentence":'Êtes-vous sûr de vouloir valider ce' +
        (_vm.selected.length > 1 ? ("s " + (_vm.selected.length)) : '') +
        ' don' +
        (_vm.selected.length > 1 ? 's' : '') +
        ' pour un montant total de ' +
        this.$options.filters.currency(_vm.amountToSend) +
        ' ?',"btn-action":"Valider"},on:{"close":function($event){_vm.dialog = false},"confirmed":function($event){return _vm.validateDons()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }